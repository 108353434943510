import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import TelegramIcon from "../assets/images/telegram-icon.svg";
import WhatsappIcon from "../assets/images/icons/whatsapp.png";
import FBIcon from "../assets/images/facebook-icon.svg";
import EyeIcon from "../assets/svg/EyeIcon";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import CashierIcon from "../assets/svg/CashierIcon";
import logo from "../assets/svg/logo_new.png";
import BotHeadIcon1 from "../assets/images/icons/btmhead_inplay.png";
import BotHeadIcon2 from "../assets/images/icons/btmhead_cricket.png";
import BotHeadIcon3 from "../assets/images/icons/btmhead_soccer.png";
import BotHeadIcon4 from "../assets/images/icons/btmhead_aviator.png";
import BotHeadIcon5 from "../assets/images/icons/btmhead_drgntgr.png";
import BotHeadIcon6 from "../assets/images/icons/btmhead_teenpati.png";
import BotHeadIcon7 from "../assets/images/icons/btmhead_roulette.png";

import SportsIcon1 from "../assets/images/games-icon/exchange_icon.png";
import HomeIcon1 from "../assets/images/games-icon/home_icon.png";
import EzugiIcon from "../assets/images/games-icon/ezugi_icon.png";
import EvolutionIcon from "../assets/images/games-icon/evolution_icon.png";
import CashierIcon1 from "../assets/images/games-icon/cashier_icon.png";
import Exchange from "../assets/images/games-icon/sports_icon.png";

import AllGames from "../assets/images/games-icon/allgames-icon.png";
import CricketIcon from "../assets/images/games-icon/cricket-icon.png";
import SoccerIcon from "../assets/images/games-icon/soccer-icon.png";
import TennisIcon from "../assets/images/games-icon/tennis-icon.png";
import GreyhoundRacingIcon from "../assets/images/games-icon/greyhoundracing-icon.png";
import HorseRacingIcon from "../assets/images/games-icon/horseracing-icon.png";
import PoliticsIcon from "../assets/images/games-icon/politics-icon.png";
import VirtualCricketIcon from "../assets/images/games-icon/virtualcricket-icon.png";
import SnookerIcon from "../assets/images/games-icon/snooker-icon.png";
import SpaceIcon from "../assets/images/games-icon/space-icon.png";

import { useSelector } from "react-redux";
import ModalWrapper from "./ModalWrapper";
import { APP_CONST } from "../config/const";

const Header = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);

  const [isSubMenuActive, setIsSubMenuActive] = useState(false);
  const handleMenuClick = () => {
    setIsSubMenuActive(!isSubMenuActive);
  };
  const inlineStyle = isSubMenuActive ? { overflowX: "clip" } : {};

  return (
    <header className="header beforeheader">
      <div className="top_head">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div className="d-flex justify-content-between align-items-center">
                <div className="logo">
                  <a href="/">
                    <img src={appDetails?.LOGO_URL} alt="Logo" />
                  </a>
                </div>

                <div className="middle-part">
                  <Navbar expand="lg">
                    <Container>
                      <Navbar.Toggle aria-controls="basic-navbar-nav" />
                      <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                          <Nav.Link href="/home">Inplay</Nav.Link>
                          <Nav.Link
                            href="/sports/Inplay"
                            className="exchange-nav"
                          >
                            Exchange
                          </Nav.Link>
                          <Nav.Link href="/betby">Sportsbook</Nav.Link>
                          <Nav.Link href="/livecasino">Live Casino</Nav.Link>
                        </Nav>
                      </Navbar.Collapse>
                    </Container>
                  </Navbar>
                </div>

                <div className="headerRight">
                  <ul className="social">
                    <li>
                      <a
                        className="panel"
                        href={
                          appDetails?.WHATS_APP_URL != ""
                            ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                            : "javascript:void(0)"
                        }
                        target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                      >
                        <img src={WhatsappIcon} alt="whats app" />
                      </a>
                    </li>
                  </ul>
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigate("/sign-in");
                    }}
                    className="button-primary btn_signin"
                  >
                    Login
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigate("/mobile-number");
                    }}
                    className="button-primary btn_signup ms-2"
                  >
                    Sign Up
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="bottom_head">
        <Container fluid>
          <Row>
            <Col xs={12} className="px-0">
              <ul style={inlineStyle}>
                <li className="allGames">
                  <a href="/sign-in" onClick={handleMenuClick}>
                    <span>Live Casino</span>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <span>Exchange</span>
                  </a>
                </li>
                <li className="allGames">
                  <a href="/sign-in" onClick={handleMenuClick}>
                    <span>Sportsbook</span>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <span>Cricket</span>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <span>Football</span>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <span>Tennis</span>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <span>Horse Racing</span>
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <Offcanvas
        className={"steps-canvas"}
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <span className="top-heading">Sign in now!</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-steps">
            <h2 className="h2">Hello Again!</h2>
            <p>
              Welcome back, you've
              <br />
              been missed!
            </p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Control type="text" placeholder="Enter Username*" />
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control type="password" placeholder="Password*" />
                <span className="icon">
                  <EyeIcon />
                </span>
              </Form.Group>
              <div className="d-flex justify-content-end mb-3">
                <a className="form-link" href="#">
                  Password Recovery
                </a>
              </div>
              <Button variant="primary" type="submit">
                Sign in
              </Button>
            </Form>
            <div className="sign-up mt-4">
              Not a member?
              <br />
              <a href="register">Sign Up Now</a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}
    </header>
  );
};

export default Header;
