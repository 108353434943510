export const pageUrlGenerator = (page) =>
  `${window.location.protocol}//${window.location.host}${page}`;

export const casinoSection = {
  popularGames: {
    AndarBaharVivo: {
      redirectUrl: "/casino/vivo-andar-bahar",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/AndarBaharVivo.jpg",
      alt: "vivo andar bahar",
      name: "Vivo Andar Bahar",
      providerName: "Vivo",
    },
    TeenPattiVivo: {
      redirectUrl: "/casino/vivo-teen-patti",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/TeenPattiVivo.jpg",
      alt: "vivo teen patti",
      name: "Vivo Teen Patti",
      providerName: "Vivo",
    },
    UltimateAndarBahar: {
      redirectUrl: "/casino/ezg-ultimate-andar-bahar",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/ultimateAB.jpg",
      alt: "",
      name: "Ultimate Andar Bahar",
      providerName: "Ezugi",
    },
    AndarBahar: {
      redirectUrl: "/casino/ezg-andar-bahar",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/andarbahar.jpg",
      alt: "",
      name: "Andar Bahar",
      providerName: "Ezugi",
    },
    aviator: {
      redirectUrl: "/casino/spribe/aviator",
      code: "aviator",
      casino: "spribe",
      provider: "aviator",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Aviator.gif",
      alt: "",
      name: "Aviator",
      providerName: "Spribe",
    },
    AutoRoulette: {
      redirectUrl: "/casino/ezg-auto-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/autoroulette.jpg",
      alt: "",
      name: "Auto Roulette",
      providerName: "Evolution",
    },
    SpeedRoulette: {
      redirectUrl: "/casino/ezgevo-speed-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/Speedroulette.jpg",
      alt: "",
      name: "Speed Roulette",
      providerName: "Evolution",
    },
    // LightningRoulette: {
    //   redirectUrl: "/casino/ezgevo-lightning-roulette",
    //   code: "1000148",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/tvshows/lightningroulette-.jpg",
    //   alt: "",
    //   name: "Lightning Roulette",
    //   providerName: "Evolution",
    // },
    DragonTiger: {
      redirectUrl: "/casino/ezg-dragon-tiger",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/Dragontigerr.jpg",
      alt: "",
      name: "Dragon Tiger",
      providerName: "Evolution",
    },
    NoCommissionBaccarat: {
      redirectUrl: "/casino/ezg-no-commission-baccarat",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/no-commission-baccarat.jpg",
      alt: "",
      name: "No Commission Baccarat",
      providerName: "Evolution",
    },
    Lucky7: {
      redirectUrl: "/casino/ezg-lucky-7",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/lucky.jpg",
      alt: "",
      name: "Lucky 7",
      providerName: "Ezugi",
    },
    Onedayteenpatti1: {
      redirectUrl: "/casino/ezg-one-day-teen-patti",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/Onedayteenpatti.jpg",
      alt: "",
      name: "One Day Teen Patti",
      providerName: "Ezugi",
    },
    CricketWar: {
      redirectUrl: "/casino/ezg-cricket-war",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/CricketWar.jpg",
      alt: "",
      name: "Cricket War",
      providerName: "Ezugi",
    },
    CricketAutoRoulette: {
      redirectUrl: "/casino/ezg-cricket-auto-roulette",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/CricketAutoRoulette.jpg",
      alt: "",
      name: "Cricket Auto Roulette",
      providerName: "Ezugi",
    },
    lightningsicbo: {
      redirectUrl: "/casino/ezgevo-gonzos-lightning-sic-bo",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/lightningsicbo.jpg",
      alt: "",
      name: "Lightning Sicbo",
      providerName: "Evolution",
    },
    UltimateRoulette: {
      redirectUrl: "/casino/ezg-ultimate-roulette",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/UltimateRoulette.jpg",
      alt: "",
      name: "Ultimate Roulette",
      providerName: "Ezugi",
    },
    // CrazyTime: {
    //   redirectUrl: "/casino/ezugi/CrazyTime",
    //   code: "1000148",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/tvshows/CrazyTime.jpg",
    //   alt: "",
    //   name: "Crazy Time",
    //   providerName: "Evolution",
    // },
  },

  AndarBahar: {
    OTTAndarBahar: {
      redirectUrl: "/casino/ezg-casino-marina-andar-bahar	",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/OTTAndarBahar.jpg",
      alt: "",
      name: "OTT Andar Bahar",
      providerName: "Ezugi",
    },
    AndarBahar: {
      redirectUrl: "/casino/ezg-andar-bahar",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/andarbahar.jpg",
      alt: "",
      name: "Andar Bahar",
      providerName: "Ezugi",
    },
    UltimateAndarBahar: {
      redirectUrl: "/casino/ezg-ultimate-andar-bahar",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/ultimateAB.jpg",
      alt: "",
      name: "Ultimate Andar Bahar",
      providerName: "Ezugi",
    },
  },

  roulettetab: {
    PortomasoRouletteVivo: {
      redirectUrl: "/casino/vivo-portomaso-roulette",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/vivo-portomaso-roulette.jpg",
      alt: "vivo portomaso roulette",
      name: "Vivo Portomaso Roulette",
      providerName: "Vivo",
    },
    OracleBlazeRouletteVivo: {
      redirectUrl: "/casino/vivo-oracle-blaze-roulette",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/vivo-oracle-blaze-roulette.jpg",
      alt: "vivo oracle blaze roulette",
      name: "Vivo Oracle Blaze Roulette",
      providerName: "Vivo",
    },
    LasVegasRouletteVivo: {
      redirectUrl: "/casino/vivo-las-vegas-roulette",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/vivo-las-vegas-roulette.jpg",
      alt: "vivo las vegas roulette",
      name: "Vivo Las Vegas Roulette",
      providerName: "Vivo",
    },
    AutoRoulette: {
      redirectUrl: "/casino/ezg-auto-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/autoroulette.jpg",
      alt: "",
      name: "Auto Roulette",
      providerName: "Evolution",
    },
    SpeedAutoRoulette: {
      redirectUrl: "/casino/ezg-speed-auto-roulette",
      code: "221002",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/Speedautoroullete.jpg",
      alt: "",
      name: "Speed Auto Roulette",
      providerName: "Ezugi",
    },
    NamasteRoulette: {
      redirectUrl: "/casino/ezg-namaste-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/namasteroulette1.jpg",
      alt: "",
      name: "Namaste Roulette",
      providerName: "Evolution",
    },
    UltimateRoulette: {
      redirectUrl: "/casino/ezg-ultimate-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/UltimateRoulette.jpg",
      alt: "",
      name: "Ultimate Roulette",
      providerName: "Ezugi",
    },
    // AutoRouletteEvo: {
    //   redirectUrl: "/casino/ezgevo-speed-roulette",
    //   code: "1000148",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/roulette/AutorouletteEvo.jpg",
    //   alt: "",
    //   name: "Auto Roulette-Evo",
    //   providerName: "Evolution",
    // },
    // LightningRoulette: {
    //   redirectUrl: "/casino/ezgevo-lightning-roulette",
    //   code: "1000148",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/roulette/lightningroulette-.jpg",
    //   alt: "",
    //   name: "Lightning Roulette",
    //   providerName: "Evolution",
    // },
    // SpeedAutoRouletteEvo: {
    //   redirectUrl: "/casino/ezgevo-speed-auto-roulette",
    //   code: "1000103",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/roulette/speed-auto-roulette.jpg",
    //   alt: "",
    //   name: "Speed Auto Roulette-EVO",
    //   providerName: "Evolution",
    // },
  },

  dragontiger: {
    DragonTigerVivo: {
      redirectUrl: "/casino/vivo-dragon-tiger",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/vivo-dragon-tiger.jpg",
      alt: "vivo dragon tiger",
      name: "Vivo Dragon Tiger",
      providerName: "Vivo",
    },
    DragonTiger: {
      redirectUrl: "/casino/ezg-dragon-tiger",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/Dragontigerr.jpg",
      alt: "",
      name: "Dragon Tiger",
      providerName: "Ezugi",
    },
    // DragonTigerEvolution: {
    //   redirectUrl: "/casino/ezgevo-dragon-tiger-evo",
    //   code: "1000148",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/tvshows/Dragontigerrevo.jpg",
    //   alt: "",
    //   name: "Dragon Tiger",
    //   providerName: "Evolution",
    // },
  },

  baccarattab: {
    MacauBaccaratVivo: {
      redirectUrl: "/casino/vivo-macau-baccarat",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/vivo-macau-baccarat.jpg",
      alt: "vivo macau baccarat",
      name: "Vivo Macau Baccarat",
      providerName: "Vivo",
    },
    Baccarat: {
      redirectUrl: "/casino/ezg-baccarat",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccaratezugi.jpg",
      alt: "",
      name: "Baccarat",
      providerName: "Ezugi",
    },
    FortuneBaccarat: {
      redirectUrl: "/casino/ezg-fortune-baccarat",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/Fortunebaccarat.png",
      alt: "",
      name: "Fortune Baccarat",
      providerName: "Ezugi",
    },
    NoCommissionBaccarat: {
      redirectUrl: "/casino/ezg-no-commission-baccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/no-commission-baccarat.jpg",
      alt: "",
      name: "No Commission Baccarat",
      providerName: "Ezugi",
    },
    SpeedCricketBaccarat: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-x",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/SpeedBaccaratx.jpg",
      alt: "",
      name: "Speed Baccarat X",
      providerName: "Evolution",
    },
    // SpeedBaccaratA: {
    //   redirectUrl: "/casino/ezugi/SpeedBaccaratA",
    //   code: "1000021",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/baccarat/SpeedBaccaratA-evo.jpg",
    //   alt: "",
    //   name: "Speed Baccarat A",
    //   providerName: "Evolution",
    // },
    // NoCommissionBaccaratEvo: {
    //   redirectUrl: "/casino/ezgevo-no-commission-baccarat-evo",
    //   code: "1000018",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/baccarat/no-commission-baccarat-evo.jpg",
    //   alt: "",
    //   name: "No Commission Baccarat-EVO",
    //   providerName: "Evolution",
    // },
  },

  tvShows: {
    Blackjack: {
      redirectUrl: "/casino/ezg-blackjack-1",
      code: "201",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack.jpg",
      alt: "",
      name: "Blackjack",
      providerName: "Ezugi",
    },
    LightningDice: {
      redirectUrl: "/casino/ezgevo-lightning-dice",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/lightningdice.jpg",
      alt: "",
      name: "Lightning Dice",
      providerName: "Evolution",
    },
    CrazyTime: {
      redirectUrl: "/casino/ezugi/CrazyTime",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/CrazyTime.jpg",
      alt: "",
      name: "Crazy Time",
      providerName: "Evolution",
    },
    Megaball: {
      redirectUrl: "/casino/ezugi/Megaball",
      code: "1000141",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/Megaball.jpg",
      alt: "",
      name: "Megaball",
      providerName: "Evolution",
    },
  },

  blackjacktab: {
    // LimitlessBlackjackVivo: {
    //   redirectUrl: "/casino/vivo-limitless-blackjack",
    //   code: "",
    //   casino: "vivo",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/popular/vivo-limitless-blackjack.jpg",
    //   alt: "vivo limitless blackjack",
    //   name: "Vivo Limitless Blackjack",
    //   providerName: "Vivo",
    // },
    LasVegasBlackjackVivo: {
      redirectUrl: "/casino/vivo-las-vegas-blackjack",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/popular/vivo-las-vegas-blackjack.jpg",
      alt: "vivo las vegas blackjack",
      name: "Vivo Las Vegas Blackjack",
      providerName: "Vivo",
    },
    // BlackjackSilverD: {
    //   redirectUrl: "/casino/ezugi/BlackjackSilverD",
    //   code: "1000044",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/blackjack/Blackjack-Silver-D.jpg",
    //   alt: "",
    //   name: "Blackjack Silver D",
    //   providerName: "Evolution",
    // },
    // BlackjackVIPE: {
    //   redirectUrl: "/casino/ezugi/BlackjackVIPE",
    //   code: "1000052",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/blackjack/Blackjack-VIP-E.jpg",
    //   alt: "",
    //   name: "Blackjack VIP E",
    //   providerName: "Evolution",
    // },
    // SalonPriveBlackjackC: {
    //   redirectUrl: "/casino/ezugi/SalonPriveBlackjackC",
    //   code: "1000070",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/blackjack/Salon-Prive-Blackjack-C.jpg",
    //   alt: "",
    //   name: "Salon Prive Blackjack C",
    //   providerName: "Evolution",
    // },
    // SpeedBlackjackD: {
    //   redirectUrl: "/casino/ezugi/SpeedBlackjackD",
    //   code: "1000033",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/blackjack/Speed-Blackjack-D1.jpg",
    //   alt: "",
    //   name: "Speed Blackjack D",
    //   providerName: "Evolution",
    // },
    // BlackjackVIPL: {
    //   redirectUrl: "/casino/ezugi/BlackjackVIPL",
    //   code: "1000056",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/blackjack/Blackjack-VIP-L.jpg",
    //   alt: "",
    //   name: "Blackjack VIP L",
    //   providerName: "Evolution",
    // },
    BlackjackGold3: {
      redirectUrl: "/casino/ezg-rumba-blackjack-2",
      code: "203",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/rumbablackjack2.jpg",
      alt: "",
      name: " Rumba Blackjack 2  ",
      providerName: "Ezugi",
    },
    // ClassicSpeedBlackjack3: {
    //   redirectUrl: "/casino/ezugi/ClassicSpeedBlackjack3",
    //   code: "1000228",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/blackjack/Speed-Blackjack-3.jpg",
    //   alt: "",
    //   name: " Classic Speed Blackjack 3  ",
    //   providerName: "Evolution",
    // },
  },

  pokertab: {
    Hand2CasinoHoldem: {
      redirectUrl: "/casino/ezgevo-2-hand-casino-holdem",
      code: "1000073",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/2handcasinoholem.jpg",
      alt: "",
      name: " 2 Hand Casino Holdem",
      providerName: "Evolution",
    },
    BetonTeenPatti: {
      redirectUrl: "/casino/ezg-bet-on-teen-patti",
      code: "227101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/Betonteenpatti.jpg",
      alt: "",
      name: "Bet on Teen Patti",
      providerName: "Ezugi",
    },
    // SideBetCity: {
    //   redirectUrl: "/casino/ezugi/SideBetCity",
    //   code: "1000115",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/poker/Sidebetcity.jpg",
    //   alt: "",
    //   name: "Side Bet City",
    //   providerName: "Evolution",
    // },
    TeenPatti3card: {
      redirectUrl: "/casino/ezugi/TeenPatti3card",
      code: "227100",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/Teenpatti3card.jpg",
      alt: "",
      name: " Teen Patti 3 Card",
      providerName: "Ezugi",
    },
    TexasHoldembonus: {
      redirectUrl: "/casino/ezgevo-texas-holdem-bonus-poker",
      code: "1000111",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/TexasHoldembonus.jpg",
      alt: "",
      name: "Texas Holdem Bonus",
      providerName: "Evolution",
    },
    // Ultimatetexasholdem: {
    //   redirectUrl: "/casino/ezugi/Ultimatetexasholdem",
    //   code: "1000151",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/poker/ultimatetaxasholden.jpg",
    //   alt: "",
    //   name: "Ultimate Texas Holdem",
    //   providerName: "Evolution",
    // },
  },

  spribe: {
    aviator: {
      redirectUrl: "/casino/spribe/aviator",
      code: "aviator",
      casino: "spribe",
      provider: "aviator",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Aviator.gif",
      alt: "",
      name: "Aviator",
      providerName: "Spribe",
    },
    mines: {
      redirectUrl: "/casino/spribe/mines",
      code: "mines",
      casino: "spribe",
      provider: "mines",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Mines.jpg",
      alt: "",
      name: "Mines",
      providerName: "Spribe",
    },
    goal: {
      redirectUrl: "/casino/spribe/goal",
      code: "goal",
      casino: "spribe",
      provider: "goal",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Goal.jpg",
      alt: "",
      name: "Goal",
      providerName: "Spribe",
    },
    dice: {
      redirectUrl: "/casino/spribe/dice",
      code: "dice",
      casino: "spribe",
      provider: "dice",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Dice.jpg",
      alt: "",
      name: "Dice",
      providerName: "Spribe",
    },
    plinko: {
      redirectUrl: "/casino/spribe/plinko",
      code: "plinko",
      casino: "spribe",
      provider: "plinko",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Plinko.jpg",
      alt: "",
      name: "Plinko",
      providerName: "Spribe",
    },
    miniroulette: {
      redirectUrl: "/casino/spribe/miniroulette",
      code: "mini-roulette",
      casino: "spribe",
      provider: "mini-roulette",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/MiniROulette.jpg",
      alt: "",
      name: "Mini Roulette",
      providerName: "Spribe",
    },
    hilo: {
      redirectUrl: "/casino/spribe/hilo",
      code: "hi-lo",
      casino: "spribe",
      provider: "hi-lo",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Hilo.jpg",
      alt: "",
      name: "Hilo",
      providerName: "Spribe",
    },
  },

  supernowa: {
    RNGCasinoQueen: {
      redirectUrl: "/casino/sn-rng-casino-queen",
      code: "RCQ",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGCasinoQueen.jpg",
      alt: "",
      name: "RNG Casino Queen",
      providerName: "Supernowa",
    },
    RNGCasinoQueen2020: {
      redirectUrl: "/casino/sn-rng-casino-queen-2020",
      code: "RCQ20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGCasinoQueen2020.jpg",
      alt: "",
      name: "RNG Casino Queen 2020",
      providerName: "Supernowa",
    },

    RNGDragonTiger: {
      redirectUrl: "/casino/sn-rng-dragon-tiger",
      code: "VDT",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNG-Dragon-Tiger.jpg",
      alt: "",
      name: "RNG Dragon Tiger",
      providerName: "Supernowa",
    },
    RNGDragonTiger2020: {
      redirectUrl: "/casino/sn-rng-dragon-tiger-2020",
      code: "VDT20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGDragonTiger2020.jpg",
      alt: "",
      name: "RNG Dragon Tiger 2020",
      providerName: "Supernowa",
    },
    RNGJoker: {
      redirectUrl: "/casino/sn-rng-joker",
      code: "VJKR",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGJoker.jpg",
      alt: "",
      name: "RNG Joker",
      providerName: "Supernowa",
    },
    RNGKingRace: {
      redirectUrl: "/casino/sn-rng-king-race",
      code: "VCR",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGKingRace.jpg",
      alt: "",
      name: "RNG King Race",
      providerName: "Supernowa",
    },
    RNGLucky7: {
      redirectUrl: "/casino/sn-rng-lucky-7",
      code: "VUD7",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGLucky7.jpg",
      alt: "",
      name: "RNG Lucky 7",
      providerName: "Supernowa",
    },
    RNGTeenPatti: {
      redirectUrl: "/casino/sn-rng-teen-patti",
      code: "VTP",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGTeenPatti.jpg",
      alt: "",
      name: "RNG Teen Patti",
      providerName: "Supernowa",
    },
    RNGTeenPatti2020: {
      redirectUrl: "/casino/sn-rng-teen-patti-2020",
      code: "VTP20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGTeenPatti2020.jpg",
      alt: "",
      name: "RNG Teen Patti 2020",
      providerName: "Supernowa",
    },
    RNGWorliMatka: {
      redirectUrl: "/casino/sn-rng-worli-matka",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGWorliMatka.jpg",
      alt: "",
      name: "RNG Worli Matka",
      providerName: "Supernowa",
    },
  },

  vivo: {
    VABacarat1: {
      redirectUrl: "/casino/vivo-va-bacarat-1",
      imgUrl: "./images/Vivo/VABacarat1.jpg",
      name: "VA Bacarat 1",
      providerName: "Vivo",
    },
    VABacarat2: {
      redirectUrl: "/casino/vivo-va-bacarat-2",
      imgUrl: "./images/Vivo/VABacarat2.jpg",
      name: "VA Bacarat 2",
      providerName: "Vivo",
    },
    VABacarat3: {
      redirectUrl: "/casino/vivo-va-bacarat-3",
      imgUrl: "./images/Vivo/VABacarat3.jpg",
      name: "VA Bacarat 3",
      providerName: "Vivo",
    },
    VABacarat4: {
      redirectUrl: "/casino/vivo-va-bacarat-4",
      imgUrl: "./images/Vivo/VABacarat4.jpg",
      name: "VA Bacarat 4",
      providerName: "Vivo",
    },
    VABacarat5: {
      redirectUrl: "/casino/vivo-va-bacarat-5",
      imgUrl: "./images/Vivo/VABacarat5.jpg",
      name: "VA Bacarat 5",
      providerName: "Vivo",
    },
  },
};
